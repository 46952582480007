@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import '../mixins/variables.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #ffffff;
  overflow-x: hidden;
  height: 100%;
  font-family: 'Outfit', sans-serif !important;
}
#root {
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e4e4;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background: #6418c3;
}

@media screen and (max-width: 659px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

a {
  text-decoration: none;
}

:root {
  --color-primary: #{$color-primary};
  --color-secondary: #{$color-secondary};
  --color-secondary-light: #fed6d6;
  --color-error: #{$color-error};
  --color-success: #{$color-success};
  --color-white: #{$color-white};
  --color-disabled-hot: #{$color-disabled-hot};
  --color-grey: #{$color-grey};
}

// colors
.color-primary {
  color: $color-primary !important;
}
.color-secondary {
  color: $color-secondary !important;
}
.color-white {
  color: #ffffff !important;
}
.color-grey {
  color: #6d7d8b !important;
}
// background-colors
.bg-white {
  background-color: #fff !important;
}
.bg-primary {
  background-color: $color-primary !important;
}
.bg-secondary {
  background-color: $color-secondary !important;
}
.bg-light-secondary {
  background-color: var(--color-secondary-light) !important;
}
.bg-light-grey {
  background-color: #f9fafc !important;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.space__bw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.justify-start {
  justify-content: flex-start;
}
.align-end {
  align-items: flex-end;
}
.flex-column {
  flex-direction: column;
}
.border-square {
  border-radius: 16px;
}
.p-relative {
  position: relative;
}
.label__animation {
  animation: moveFromDown 0.4s;
  transform: translateY(-0%);
}
.label__animation__revert {
  animation: moveFromUp 0.4s;
  transform: translateY(-0%);
}
@keyframes moveFromDown {
  from {
    transform: translateY(30%);
  }
  to {
    transform: translateY(-0%);
  }
}
@keyframes moveFromUp {
  from {
    transform: translateY(30%);
  }
  to {
    transform: translateY(-0%);
  }
}

.chat-bubble {
  position: relative;
  display: inline-flex;
  margin: 0;
  padding: 0;
  line-height: 1;
  text-align: center;
  background: #ffffff00;
  border-radius: 3px;
  box-shadow: none;

  $timing: 0;

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 1px;
    background: #fff;
    animation: jump 1s ease infinite;
    animation-delay: 0.5s;

    &:nth-child(2) {
      animation-delay: 0.7s * 1.1;
    }

    &:nth-child(3) {
      animation-delay: 0.7s * 1.2;
    }
  }
}

@keyframes jump {
  0%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(3px);
  }
}
.button__animation__wrapper {
  position: relative;
  width: 291px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button__animated {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  background-color: #fff;
  color: #010103;
  border-radius: 19px;
  border: 0px;
  z-index: 9;
  width: 288px;
  height: 70px;
  position: relative;
  cursor: pointer;
}

.button__animated__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(
    90deg,
    #0b3ffa 0%,
    #dfdfdf 30.43%,
    #0b3ffa 68.23%,
    #dfdfdf 100%
  );
  background-size: 600% 600%;
  border-radius: 22px;
  animation: AnimateBorder 4s ease infinite;
  z-index: 1;
  transform: translate(-2px, -2px);
  transition: filter 1s ease-in;
}

.premiumpkg {
  position: relative;
  isolation: isolate;
  overflow: hidden;
  border-radius: 16px;
}
.premiumpkg::after {
  content: '';
  position: absolute;
  top: -45%;
  left: -33%;
  z-index: -2;
  border-radius: 100%;
  aspect-ratio: 1/1;
  height: 191%;
  background-image: linear-gradient(91deg, #1d73f3 28.06%, #f1ac7b 53.25%);
  animation: rotatePackage 2000ms linear infinite !important;
}
.premiumpkg::before {
  inset: 2px;
  content: '';
  z-index: -1;
  position: absolute;
  border-radius: 12px;
  background-color: #f7f7f7;
}
@keyframes rotatePackage {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}
@-webkit-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
