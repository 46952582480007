.circle {
  border-radius: 50%;
  background-color: rgba(237, 69, 69, 1);
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

.item {
  z-index: 100;
  padding: 5px;
}

.item img {
  width: 30px;
}
